import BrowserInteractionTime from "browser-interaction-time";

export class BrowserInteractionTimeInterface {
    public browserInteractionTime: BrowserInteractionTime;

    constructor() {
        this.browserInteractionTime = new BrowserInteractionTime({
            idleTimeoutMs: 15 * 1000,
        });
    }

    public startTimer(): void {
        this.browserInteractionTime.startTimer();
    }

    public getTimeInMilliseconds(): number {
        return this.browserInteractionTime.getTimeInMilliseconds();
    }
}
