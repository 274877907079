import StatContentType from "../model/StatContentType";
import JsonLdType, {articleSchemaTypes} from "../model/JsonLdType";
import DocumentStatusResolver from "../resolvers/document-status-resolver";

class StatContent {
    static async generate(): Promise<StatContentType> {
        const json = StatContent.resolveJson();

        const documentStatusResolver = new DocumentStatusResolver();

        const isNotFoundStatus = await documentStatusResolver.isNotFoundStatus();
        const contentType = isNotFoundStatus ? "error" : StatContent.resolveType(json);

        return {
            type: contentType,
            title: StatContent.resolveTitle(json),
            category: StatContent.resolveCategory(json),
            image: StatContent.resolveImage(json),
            author: StatContent.resolveAuthor(json),
            published: StatContent.resolvePublished(json),
            modified: StatContent.resolveModified(json),
        };
    }

    static resolveJson(): JsonLdType {
        let json = {};

        const jsonLds = document.querySelectorAll('script[type="application/ld+json"]');

        for (let i = 0; i < jsonLds.length; i++) {
            const element = jsonLds[i];

            if (element.innerHTML === '') {
                continue;
            }

            json = JSON.parse(element.innerHTML);

            const isValid = StatContent.isValidType(json);

            if (isValid) {
                break;
            }
        }

        return json;
    }

    static isValidType(json: JsonLdType): boolean {
        const type = json['@type'] ?? json['type'] ?? null;

        return articleSchemaTypes.includes(type);
    }

    static resolveTitle(json: JsonLdType): string {
        if (typeof (json.headline) !== 'undefined') {
            return json.headline;
        }

        const ogTitle = StatContent.resolveTagValue('og:title');

        if (ogTitle !== '') {
            return ogTitle;
        }

        return document.title !== '' ? document.title : '';
    }

    static resolveImage(json: JsonLdType): string {
        if (Array.isArray(json.image) && json.image.length > 0) {
            return json.image.at(0);
        }

        if (!Array.isArray(json.image) && json.image?.url?.length > 0) {
            return json.image.url;
        }

        return StatContent.resolveTagValue('og:image');
    }

    static resolveType(json: JsonLdType): string {
        const contentType = StatContent.resolveTagValue('stats:type');

        if (contentType !== '') {
            return contentType;
        }

        if (this.isValidType(json)) {
            return 'articles';
        }

        return 'section';
    }

    static resolveCategory(json: JsonLdType): string {
        if (json.articleSection?.length > 0) {
            if (typeof json.articleSection === 'string') {
                return json.articleSection;
            }

            return json.articleSection.at(0);
        }

        return StatContent.resolveTagValue('article:section');
    }

    static resolveAuthor(json: JsonLdType): string|string[] {
        if (Array.isArray(json.author) && json.author.length > 0) {
            return json.author.map((author) => author.name);
        }

        if (!Array.isArray(json.author) && json.author?.name?.length > 0) {
            return json.author.name;
        }

        return StatContent.resolveTagValue('author', 'name');
    }

    static resolvePublished(json: JsonLdType): number {
        if (json.datePublished?.length > 0 && this.isDateValid(json.datePublished)) {
            return this.convertDateToTimestamp(json.datePublished);
        }

        const publishedValue = StatContent.resolveTagValue('article:published_time');

        return this.isDateValid(publishedValue) ? this.convertDateToTimestamp(publishedValue) : 0;
    }

    static isDateValid(date: string): boolean {
        return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,9})?(?:[+-]\d{2}:?\d{2}|Z)$/.test(date);
    }

    static convertDateToTimestamp(date: string): number {
        return new Date(date).getTime();
    }

    static resolveModified(json: JsonLdType): number {
        if (json.dateModified?.length > 0 && this.isDateValid(json.dateModified)) {
            return this.convertDateToTimestamp(json.dateModified);
        }

        const modifiedValue = StatContent.resolveTagValue('article:modified_time')

        return this.isDateValid(modifiedValue) ? this.convertDateToTimestamp(modifiedValue) : 0;
    }

    static resolveTagValue(tag: string, attribute = 'property'): string {
        const item = document.head.querySelector(`meta[${attribute}="${tag}"]`);

        if (item === null) {
            return '';
        }

        return item.getAttribute('content');
    }
}

export default StatContent;
